/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const RichText = ({ children, ...props }) => {
  return <Box dangerouslySetInnerHTML={{ __html: children }} {...props} />;
};

RichText.propTypes = {
  children: PropTypes.node,
};

export { RichText };
