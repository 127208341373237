import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, RichText } from './components';

const ProjectContent = ({ content }) => {
  return (
    <Wrapper>
      <RichText {...{ content }} />
    </Wrapper>
  );
};

ProjectContent.propTypes = {
  content: PropTypes.node,
};

ProjectContent.defaultProps = {
  content: null,
};

export default ProjectContent;
