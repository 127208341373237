/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { Links, Link } from './components';

const ProjectNavLinks = ({ next, previous }) => {
  return (
    <Links>
      {next && (
        <Link
          to={`/project/${next.slug}`}
          sx={{ ':before': { content: '"<< "' } }}
        >
          Prev
        </Link>
      )}{' '}
      {previous && (
        <Link
          to={`/project/${previous.slug}`}
          sx={{ ':after': { content: '" >>"' } }}
        >
          Next
        </Link>
      )}
    </Links>
  );
};

export default ProjectNavLinks;

ProjectNavLinks.propTypes = {
  next: PropTypes.object,
  previous: PropTypes.object,
};

ProjectNavLinks.defaultProps = {
  next: null,
  previous: null,
};
