/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { HorizontalScroll } from '../horizontal-scroll';
import { FIXED_IMAGE_HEIGHT, PROJECT_TITLE_HEIGHT } from '../full-height-image';
import { HEADER_HEIGHT } from '../header';
import { FOOTER_HEIGHT } from '../footer';

const Wrapper = ({ children, ...props }) => (
  <Box
    {...props}
    id="project-content"
    sx={{
      margin: '0 auto',
      'h1, figcaption': {
        textAlign: 'center',
      },
      'h1, figcaption, p': {
        maxWidth: ['initial', null, null, '80%'],
      },
    }}
  >
    {children}
  </Box>
);

Wrapper.propTypes = {
  children: PropTypes.node,
};

const RichText = ({ content, ...props }) => (
  <HorizontalScroll
    className="rich-text"
    sx={{
      height: `calc(100vh - ${HEADER_HEIGHT.lg} - ${FOOTER_HEIGHT})`, // ensure scrollbar is shown
      mt: [0, null, null, HEADER_HEIGHT.lg],
      pl: [0, null, null, 3],
      '& figure': {
        maxWidth: '100%',
        mb: 0,
        '.gatsby-image-wrapper': {
          // Hack Gatsby Image to be static and "not fluid"
          // This is necessary since aspect ratio is unknown,
          // due to the images in HTML rather than sourced.
          'span[aria-hidden="true"], img[aria-hidden="true"], source': {
            display: 'none',
          },
          '& img': {
            position: 'static !important',
            mb: 0,
          },
          '@media (orientation: landscape)': {
            height: '100vh',
            width: 'max-content !important',
            '& img': {
              // Image width can be inferred from height with aspect ratio
              // This is possible because the img is `position:static`.
              height: `calc(${FIXED_IMAGE_HEIGHT}) !important`,
              mb: PROJECT_TITLE_HEIGHT, // space between image and scrollbar
              width: 'auto !important',
            },
          },
        },
      },
      p: {
        minWidth: [null, null, null, '230px'],
      },
    }}
    dangerouslySetInnerHTML={{ __html: content }}
    {...props}
  />
);

RichText.propTypes = {
  content: PropTypes.node.isRequired,
};

export { Wrapper, RichText };
