/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { Wrapper, Heading, Body, AcfContent } from './components';

const ProjectInfo = ({ projectInfo, isMenuOpen, setIsMenuOpen, title }) => {
  const hasContent = !!projectInfo.content;

  return (
    <Wrapper>
      <Heading
        isOpen={isMenuOpen}
        hasContent={hasContent}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {title}
      </Heading>
      {projectInfo.content && (
        <Body isOpen={isMenuOpen}>
          <AcfContent>{projectInfo.content}</AcfContent>
        </Body>
      )}
    </Wrapper>
  );
};

ProjectInfo.propTypes = {
  projectInfo: PropTypes.shape({
    client: PropTypes.string,
    agency: PropTypes.string,
    credit: PropTypes.string,
    content: PropTypes.node,
  }),
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func,
  title: PropTypes.string,
};

ProjectInfo.defaultProps = {
  isMenuOpen: false,
  setIsMenuOpen: null,
  title: null,
};

export default ProjectInfo;
