/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import PropTypes from 'prop-types';

import { RichText } from '../rich-text';

const Wrapper = ({ children }) => (
  <Box
    sx={{
      mt: [0, null, null, 3],
      pb: [3, null, null, 0],
      pl: [2, null, null, 0],
    }}
  >
    {children}
  </Box>
);

Wrapper.propTypes = {
  children: PropTypes.node,
};

const Heading = ({ children, hasContent, isOpen, ...props }) => {
  return (
    <Box
      {...props}
      as="h2"
      sx={{
        color: 'highlight',
        fontFamily: 'body',
        fontSize: [0, 1, null, 2],
        mb: 1,
        listStyle: 'none',
        textTransform: 'uppercase',
        transition: '0.5s all ease-in-out',
        cursor: hasContent && 'pointer',
        ':after': {
          content: [
            isOpen ? hasContent && `" -"` : hasContent && `" +"`,
            null,
            'none',
          ],
        },
      }}
    >
      {children}
    </Box>
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  isOpen: PropTypes.bool,
};

const Body = ({ children, isOpen }) => (
  <Box
    sx={{
      maxHeight: [isOpen ? '100vh' : 0, null, null, 'unset'],
      overflow: 'hidden',
      opacity: [isOpen ? '100%' : 0, null, null, 'unset'],
      color: 'white',
      mt: [isOpen ? 3 : 0, null, null, 3],
      fontFamily: 'body',
      fontSize: [0, null, null, 1],
      lineHeight: 'body',
      transition: '0.5s all ease-in-out',
    }}
  >
    {children}
  </Box>
);

Body.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

const AcfContent = ({ children }) => (
  <RichText
    sx={{
      '& h3': {
        fontSize: [0, null, null, 1],
        mb: 1,
        textTransform: 'uppercase',
      },
      '& p': {
        mb: 2,
      },
    }}
  >
    {children}
  </RichText>
);

AcfContent.propTypes = {
  children: PropTypes.node,
};

export { Wrapper, Heading, Body, AcfContent };
