/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { filter, intersection, size, findIndex } from 'lodash';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Menu from '../components/menu';
import ProjectNavLinks from '../components/project-nav-links';
import ProjectContent from '../components/project-content';
import ProjectInfo from '../components/project-info';

import { useFilters } from '../context/filters-context';
import { useMenu } from '../context/menu-context';
import { HEADER_HEIGHT } from '../components/header';

export const projectQuery = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      content
      excerpt
      projectInfo {
        content
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;

const Project = ({ data, pageContext }) => {
  const { projectInfo, excerpt, content, slug, title } = data.wpPost;
  const { projects } = pageContext;

  const { isMenuOpen, setIsMenuOpen } = useMenu();

  const { state } = useFilters();
  const currentFilters = state.filters;

  const getFilteredProjects = posts =>
    filter(posts, ({ tags }) => {
      const currentFiltersMatches = intersection(tags, currentFilters);
      return size(currentFiltersMatches) > 0;
    });
  const filteredProjects =
    size(currentFilters) > 0 ? getFilteredProjects(projects) : projects;
  const currentProjectIndex = findIndex(filteredProjects, ['slug', slug]);
  const previous = filteredProjects[currentProjectIndex + 1];
  const next = filteredProjects[currentProjectIndex - 1];

  return (
    <Fragment>
      <Box
        sx={{
          display: ['block', null, null, 'none'],
          flex: '1 0 130px',
          mt: HEADER_HEIGHT.sm,
        }}
      >
        {projectInfo && (
          <ProjectInfo
            {...{
              projectInfo,
              excerpt,
              isMenuOpen,
              setIsMenuOpen,
              title,
            }}
          />
        )}
      </Box>
      <ProjectContent {...{ title, content }} />
      <Menu width="200px">
        <ProjectNavLinks {...{ next, previous }} />
        <Box sx={{ display: ['none', null, null, 'block'] }}>
          {projectInfo && (
            <ProjectInfo
              {...{
                projectInfo,
                excerpt,
                isMenuOpen,
                setIsMenuOpen,
                title,
              }}
            />
          )}
        </Box>
      </Menu>
    </Fragment>
  );
};

Project.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    projects: PropTypes.array,
  }).isRequired,
};

export default Project;
