/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

const Links = ({ children }) => (
  <Flex
    as="ul"
    sx={{
      justifyContent: 'space-between',
      m: 0,
      '*:not(:last-child)': { mr: 2 },
    }}
  >
    {children}
  </Flex>
);

Links.propTypes = {
  children: PropTypes.node,
};

const Link = ({ children, ...props }) => (
  <GatsbyLink
    {...props}
    sx={{
      variant: 'links',
      fontSize: [0, 1, null],
      fontStyle: 'italic',
      textTransform: 'uppercase',
      textDecoration: 'none',
    }}
  >
    {children}
  </GatsbyLink>
);

Link.propTypes = {
  children: PropTypes.node,
};

export { Links, Link };
